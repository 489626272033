/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MachineGroup } from './MachineGroup';
import {
    MachineGroupFromJSON,
    MachineGroupFromJSONTyped,
    MachineGroupToJSON,
} from './MachineGroup';

/**
 * 
 * @export
 * @interface GetContractMachineGroups200ResponseInner
 */
export interface GetContractMachineGroups200ResponseInner {
    /**
     * 
     * @type {MachineGroup}
     * @memberof GetContractMachineGroups200ResponseInner
     */
    machineGroup: MachineGroup;
    /**
     * 
     * @type {number}
     * @memberof GetContractMachineGroups200ResponseInner
     */
    manPower: number;
}

/**
 * Check if a given object implements the GetContractMachineGroups200ResponseInner interface.
 */
export function instanceOfGetContractMachineGroups200ResponseInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machineGroup" in value;
    isInstance = isInstance && "manPower" in value;

    return isInstance;
}

export function GetContractMachineGroups200ResponseInnerFromJSON(json: any): GetContractMachineGroups200ResponseInner {
    return GetContractMachineGroups200ResponseInnerFromJSONTyped(json, false);
}

export function GetContractMachineGroups200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContractMachineGroups200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineGroup': MachineGroupFromJSON(json['machineGroup']),
        'manPower': json['manPower'],
    };
}

export function GetContractMachineGroups200ResponseInnerToJSON(value?: GetContractMachineGroups200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineGroup': MachineGroupToJSON(value.machineGroup),
        'manPower': value.manPower,
    };
}

