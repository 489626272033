/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineGroup
 */
export interface MachineGroup {
    /**
     * 
     * @type {string}
     * @memberof MachineGroup
     */
    internalName: string;
    /**
     * 
     * @type {string}
     * @memberof MachineGroup
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof MachineGroup
     */
    reportName: string;
    /**
     * 
     * @type {string}
     * @memberof MachineGroup
     */
    railiumName: string;
}

/**
 * Check if a given object implements the MachineGroup interface.
 */
export function instanceOfMachineGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internalName" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "reportName" in value;
    isInstance = isInstance && "railiumName" in value;

    return isInstance;
}

export function MachineGroupFromJSON(json: any): MachineGroup {
    return MachineGroupFromJSONTyped(json, false);
}

export function MachineGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internalName': json['internalName'],
        'displayName': json['displayName'],
        'reportName': json['reportName'],
        'railiumName': json['railiumName'],
    };
}

export function MachineGroupToJSON(value?: MachineGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internalName': value.internalName,
        'displayName': value.displayName,
        'reportName': value.reportName,
        'railiumName': value.railiumName,
    };
}

