/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyMasterData } from './CompanyMasterData';
import {
    CompanyMasterDataFromJSON,
    CompanyMasterDataFromJSONTyped,
    CompanyMasterDataToJSON,
} from './CompanyMasterData';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {CompanyMasterData}
     * @memberof Company
     */
    technicalManagement: CompanyMasterData;
    /**
     * 
     * @type {CompanyMasterData}
     * @memberof Company
     */
    accountingManagement: CompanyMasterData;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    vatId?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    debitorId: number;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "technicalManagement" in value;
    isInstance = isInstance && "accountingManagement" in value;
    isInstance = isInstance && "debitorId" in value;

    return isInstance;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'technicalManagement': CompanyMasterDataFromJSON(json['technicalManagement']),
        'accountingManagement': CompanyMasterDataFromJSON(json['accountingManagement']),
        'vatId': !exists(json, 'vatId') ? undefined : json['vatId'],
        'debitorId': json['debitorId'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'technicalManagement': CompanyMasterDataToJSON(value.technicalManagement),
        'accountingManagement': CompanyMasterDataToJSON(value.accountingManagement),
        'vatId': value.vatId,
        'debitorId': value.debitorId,
    };
}

