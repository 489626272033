/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DbbgEinsatzEinsatzzeit } from './DbbgEinsatzEinsatzzeit';
import {
    DbbgEinsatzEinsatzzeitFromJSON,
    DbbgEinsatzEinsatzzeitFromJSONTyped,
    DbbgEinsatzEinsatzzeitToJSON,
} from './DbbgEinsatzEinsatzzeit';
import type { DbbgEinsatzSchicht } from './DbbgEinsatzSchicht';
import {
    DbbgEinsatzSchichtFromJSON,
    DbbgEinsatzSchichtFromJSONTyped,
    DbbgEinsatzSchichtToJSON,
} from './DbbgEinsatzSchicht';

/**
 * 
 * @export
 * @interface DbbgEinsatzOperationenVerarbeitungsVorschau
 */
export interface DbbgEinsatzOperationenVerarbeitungsVorschau {
    /**
     * 
     * @type {Array<string>}
     * @memberof DbbgEinsatzOperationenVerarbeitungsVorschau
     */
    warnungen: Array<string>;
    /**
     * 
     * @type {Array<DbbgEinsatzEinsatzzeit>}
     * @memberof DbbgEinsatzOperationenVerarbeitungsVorschau
     */
    einsatzzeiten: Array<DbbgEinsatzEinsatzzeit>;
    /**
     * 
     * @type {Array<DbbgEinsatzSchicht>}
     * @memberof DbbgEinsatzOperationenVerarbeitungsVorschau
     */
    schichten: Array<DbbgEinsatzSchicht>;
}

/**
 * Check if a given object implements the DbbgEinsatzOperationenVerarbeitungsVorschau interface.
 */
export function instanceOfDbbgEinsatzOperationenVerarbeitungsVorschau(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "warnungen" in value;
    isInstance = isInstance && "einsatzzeiten" in value;
    isInstance = isInstance && "schichten" in value;

    return isInstance;
}

export function DbbgEinsatzOperationenVerarbeitungsVorschauFromJSON(json: any): DbbgEinsatzOperationenVerarbeitungsVorschau {
    return DbbgEinsatzOperationenVerarbeitungsVorschauFromJSONTyped(json, false);
}

export function DbbgEinsatzOperationenVerarbeitungsVorschauFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzOperationenVerarbeitungsVorschau {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'warnungen': json['Warnungen'],
        'einsatzzeiten': ((json['Einsatzzeiten'] as Array<any>).map(DbbgEinsatzEinsatzzeitFromJSON)),
        'schichten': ((json['Schichten'] as Array<any>).map(DbbgEinsatzSchichtFromJSON)),
    };
}

export function DbbgEinsatzOperationenVerarbeitungsVorschauToJSON(value?: DbbgEinsatzOperationenVerarbeitungsVorschau | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Warnungen': value.warnungen,
        'Einsatzzeiten': ((value.einsatzzeiten as Array<any>).map(DbbgEinsatzEinsatzzeitToJSON)),
        'Schichten': ((value.schichten as Array<any>).map(DbbgEinsatzSchichtToJSON)),
    };
}

