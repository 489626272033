/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Section } from './Section';
import {
    SectionFromJSON,
    SectionFromJSONTyped,
    SectionToJSON,
} from './Section';

/**
 * 
 * @export
 * @interface CreateSectionRequest
 */
export interface CreateSectionRequest {
    /**
     * 
     * @type {Section}
     * @memberof CreateSectionRequest
     */
    section: Section;
}

/**
 * Check if a given object implements the CreateSectionRequest interface.
 */
export function instanceOfCreateSectionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "section" in value;

    return isInstance;
}

export function CreateSectionRequestFromJSON(json: any): CreateSectionRequest {
    return CreateSectionRequestFromJSONTyped(json, false);
}

export function CreateSectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': SectionFromJSON(json['section']),
    };
}

export function CreateSectionRequestToJSON(value?: CreateSectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': SectionToJSON(value.section),
    };
}

