/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgEinsatzGruppeAuswahlElement
 */
export interface DbbgEinsatzGruppeAuswahlElement {
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    maschinengruppe: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    einsatzort: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    bis: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppeAuswahlElement
     */
    externeBestellnummer: string;
}

/**
 * Check if a given object implements the DbbgEinsatzGruppeAuswahlElement interface.
 */
export function instanceOfDbbgEinsatzGruppeAuswahlElement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "maschinengruppe" in value;
    isInstance = isInstance && "einsatzort" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "externeBestellnummer" in value;

    return isInstance;
}

export function DbbgEinsatzGruppeAuswahlElementFromJSON(json: any): DbbgEinsatzGruppeAuswahlElement {
    return DbbgEinsatzGruppeAuswahlElementFromJSONTyped(json, false);
}

export function DbbgEinsatzGruppeAuswahlElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzGruppeAuswahlElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'maschinengruppe': json['Maschinengruppe'],
        'einsatzort': json['Einsatzort'],
        'von': json['Von'],
        'bis': json['Bis'],
        'externeBestellnummer': json['ExterneBestellnummer'],
    };
}

export function DbbgEinsatzGruppeAuswahlElementToJSON(value?: DbbgEinsatzGruppeAuswahlElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Maschinengruppe': value.maschinengruppe,
        'Einsatzort': value.einsatzort,
        'Von': value.von,
        'Bis': value.bis,
        'ExterneBestellnummer': value.externeBestellnummer,
    };
}

