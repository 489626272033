import { computed } from "vue";
import { useOauthPermissionsInfo } from "./useOauthPermissionsInfo";
import { oauthRoles } from "@/authConfig";

export function useOauthRoles() {
  const oauthPermissions = useOauthPermissionsInfo();

  const isUserGeneratorAdmin = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.GENERATOR_ADMIN),
  );
  const isUserReportAdmin = computed(() =>
   oauthPermissions.hasAnyRole(oauthRoles.REPORT_ADMIN),
    );
  const canUserReadReports = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_READ,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserSubmitReport = computed(() =>
    oauthPermissions.hasAnyRole(oauthRoles.REPORT_SUBMIT),
  );
  const canUserReviewReport = computed(() => 
  oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_REVIEW,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserBillReport = computed(() => 
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_BILLING,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserCommunicateExternal = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_EXTERNAL,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserApproveReport = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_APPROVAL,
      oauthRoles.REPORT_ADMIN,
    ),
  );
  const canUserAssignReports = computed(() =>
    oauthPermissions.hasAnyRole(
      oauthRoles.REPORT_ASSIGNMENTS,
      oauthRoles.REPORT_ADMIN,
    ),
  );

  return {
    isUserGeneratorAdmin,
    isUserReportAdmin,
    canUserReadReports,
    canUserSubmitReport,
    canUserReviewReport,
    canUserBillReport,
    canUserCommunicateExternal,
    canUserApproveReport,
    canUserAssignReports,
  };
}
