/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BenutzerEreignis } from './BenutzerEreignis';
import {
    BenutzerEreignisFromJSON,
    BenutzerEreignisFromJSONTyped,
    BenutzerEreignisToJSON,
} from './BenutzerEreignis';
import type { BerichtsStatus } from './BerichtsStatus';
import {
    BerichtsStatusFromJSON,
    BerichtsStatusFromJSONTyped,
    BerichtsStatusToJSON,
} from './BerichtsStatus';
import type { DbbgRailiumEinsatz } from './DbbgRailiumEinsatz';
import {
    DbbgRailiumEinsatzFromJSON,
    DbbgRailiumEinsatzFromJSONTyped,
    DbbgRailiumEinsatzToJSON,
} from './DbbgRailiumEinsatz';

/**
 * 
 * @export
 * @interface DbbgEinsatzGruppe
 */
export interface DbbgEinsatzGruppe {
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    id: number;
    /**
     * 
     * @type {BerichtsStatus}
     * @memberof DbbgEinsatzGruppe
     */
    status: BerichtsStatus;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    jahr: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    vertragsnummer: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    bis: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    berichtsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    einsatzort: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    externeBestellnummerRegion: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    externeBestellnummerNummer: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzGruppe
     */
    bestellnummer?: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    zufhrungKilometer?: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    sonderzufhrungKilometer?: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzGruppe
     */
    sonderzufhrungPersonal?: number;
    /**
     * 
     * @type {Array<DbbgRailiumEinsatz>}
     * @memberof DbbgEinsatzGruppe
     */
    railiumTasks: Array<DbbgRailiumEinsatz>;
    /**
     * 
     * @type {BenutzerEreignis}
     * @memberof DbbgEinsatzGruppe
     */
    faploHochgeladen?: BenutzerEreignis;
}

/**
 * Check if a given object implements the DbbgEinsatzGruppe interface.
 */
export function instanceOfDbbgEinsatzGruppe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "jahr" in value;
    isInstance = isInstance && "vertragsnummer" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "einsatzort" in value;
    isInstance = isInstance && "externeBestellnummerRegion" in value;
    isInstance = isInstance && "externeBestellnummerNummer" in value;
    isInstance = isInstance && "railiumTasks" in value;

    return isInstance;
}

export function DbbgEinsatzGruppeFromJSON(json: any): DbbgEinsatzGruppe {
    return DbbgEinsatzGruppeFromJSONTyped(json, false);
}

export function DbbgEinsatzGruppeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzGruppe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'status': BerichtsStatusFromJSON(json['Status']),
        'jahr': json['Jahr'],
        'vertragsnummer': json['Vertragsnummer'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'von': json['Von'],
        'bis': json['Bis'],
        'berichtsNummer': json['BerichtsNummer'],
        'einsatzort': json['Einsatzort'],
        'externeBestellnummerRegion': json['ExterneBestellnummerRegion'],
        'externeBestellnummerNummer': json['ExterneBestellnummerNummer'],
        'bestellnummer': !exists(json, 'Bestellnummer') ? undefined : json['Bestellnummer'],
        'zufhrungKilometer': !exists(json, 'ZuführungKilometer') ? undefined : json['ZuführungKilometer'],
        'sonderzufhrungKilometer': !exists(json, 'SonderzuführungKilometer') ? undefined : json['SonderzuführungKilometer'],
        'sonderzufhrungPersonal': !exists(json, 'SonderzuführungPersonal') ? undefined : json['SonderzuführungPersonal'],
        'railiumTasks': ((json['RailiumTasks'] as Array<any>).map(DbbgRailiumEinsatzFromJSON)),
        'faploHochgeladen': !exists(json, 'FaploHochgeladen') ? undefined : BenutzerEreignisFromJSON(json['FaploHochgeladen']),
    };
}

export function DbbgEinsatzGruppeToJSON(value?: DbbgEinsatzGruppe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Status': BerichtsStatusToJSON(value.status),
        'Jahr': value.jahr,
        'Vertragsnummer': value.vertragsnummer,
        'MaschinenGruppe': value.maschinenGruppe,
        'Von': value.von,
        'Bis': value.bis,
        'BerichtsNummer': value.berichtsNummer,
        'Einsatzort': value.einsatzort,
        'ExterneBestellnummerRegion': value.externeBestellnummerRegion,
        'ExterneBestellnummerNummer': value.externeBestellnummerNummer,
        'Bestellnummer': value.bestellnummer,
        'ZuführungKilometer': value.zufhrungKilometer,
        'SonderzuführungKilometer': value.sonderzufhrungKilometer,
        'SonderzuführungPersonal': value.sonderzufhrungPersonal,
        'RailiumTasks': ((value.railiumTasks as Array<any>).map(DbbgRailiumEinsatzToJSON)),
        'FaploHochgeladen': BenutzerEreignisToJSON(value.faploHochgeladen),
    };
}

