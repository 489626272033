/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyMasterData
 */
export interface CompanyMasterData {
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    additionalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    poBox?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyMasterData
     */
    zipCode: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMasterData
     */
    country?: string;
}

/**
 * Check if a given object implements the CompanyMasterData interface.
 */
export function instanceOfCompanyMasterData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "additionalAddress" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function CompanyMasterDataFromJSON(json: any): CompanyMasterData {
    return CompanyMasterDataFromJSONTyped(json, false);
}

export function CompanyMasterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMasterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additionalAddress': json['additionalAddress'],
        'street': json['street'],
        'poBox': !exists(json, 'poBox') ? undefined : json['poBox'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'phone': json['phone'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function CompanyMasterDataToJSON(value?: CompanyMasterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additionalAddress': value.additionalAddress,
        'street': value.street,
        'poBox': value.poBox,
        'zipCode': value.zipCode,
        'city': value.city,
        'phone': value.phone,
        'country': value.country,
    };
}

