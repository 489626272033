/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonReportCheck } from './JsonReportCheck';
import {
    JsonReportCheckFromJSON,
    JsonReportCheckFromJSONTyped,
    JsonReportCheckToJSON,
} from './JsonReportCheck';

/**
 * 
 * @export
 * @interface JsonMachineGroupReportsCheck
 */
export interface JsonMachineGroupReportsCheck {
    /**
     * 
     * @type {number}
     * @memberof JsonMachineGroupReportsCheck
     */
    vertragsNummer: number;
    /**
     * 
     * @type {string}
     * @memberof JsonMachineGroupReportsCheck
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {Array<JsonReportCheck>}
     * @memberof JsonMachineGroupReportsCheck
     */
    berichte: Array<JsonReportCheck>;
}

/**
 * Check if a given object implements the JsonMachineGroupReportsCheck interface.
 */
export function instanceOfJsonMachineGroupReportsCheck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vertragsNummer" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berichte" in value;

    return isInstance;
}

export function JsonMachineGroupReportsCheckFromJSON(json: any): JsonMachineGroupReportsCheck {
    return JsonMachineGroupReportsCheckFromJSONTyped(json, false);
}

export function JsonMachineGroupReportsCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonMachineGroupReportsCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertragsNummer': json['VertragsNummer'],
        'maschinenGruppe': json['MaschinenGruppe'],
        'berichte': ((json['Berichte'] as Array<any>).map(JsonReportCheckFromJSON)),
    };
}

export function JsonMachineGroupReportsCheckToJSON(value?: JsonMachineGroupReportsCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'VertragsNummer': value.vertragsNummer,
        'MaschinenGruppe': value.maschinenGruppe,
        'Berichte': ((value.berichte as Array<any>).map(JsonReportCheckToJSON)),
    };
}

