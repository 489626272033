/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetZipRequest
 */
export interface GetZipRequest {
    /**
     * 
     * @type {string}
     * @memberof GetZipRequest
     */
    machineGroup: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetZipRequest
     */
    clearReports?: boolean;
}

/**
 * Check if a given object implements the GetZipRequest interface.
 */
export function instanceOfGetZipRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "machineGroup" in value;

    return isInstance;
}

export function GetZipRequestFromJSON(json: any): GetZipRequest {
    return GetZipRequestFromJSONTyped(json, false);
}

export function GetZipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetZipRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineGroup': json['machineGroup'],
        'clearReports': !exists(json, 'clearReports') ? undefined : json['clearReports'],
    };
}

export function GetZipRequestToJSON(value?: GetZipRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineGroup': value.machineGroup,
        'clearReports': value.clearReports,
    };
}

