/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contract } from './Contract';
import {
    ContractFromJSON,
    ContractFromJSONTyped,
    ContractToJSON,
} from './Contract';

/**
 * 
 * @export
 * @interface CreateContractRequest
 */
export interface CreateContractRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateContractRequest
     */
    companyId: number;
    /**
     * 
     * @type {Contract}
     * @memberof CreateContractRequest
     */
    contract: Contract;
}

/**
 * Check if a given object implements the CreateContractRequest interface.
 */
export function instanceOfCreateContractRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "contract" in value;

    return isInstance;
}

export function CreateContractRequestFromJSON(json: any): CreateContractRequest {
    return CreateContractRequestFromJSONTyped(json, false);
}

export function CreateContractRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContractRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'contract': ContractFromJSON(json['contract']),
    };
}

export function CreateContractRequestToJSON(value?: CreateContractRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'contract': ContractToJSON(value.contract),
    };
}

