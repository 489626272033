import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FailedView from "../views/FailedView.vue";
import { RouteNames } from './RouteNames';
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/failed",
    name: "Failed",
    component: FailedView,
  },
  {
    path: "/overview",
    name: RouteNames.JOB_OVERVIEW,
    component: () => import("../views/JobsOverview.vue"),
    meta: {
      requiresAuth: true,
      headline: "Berichtsübersicht",
    },
  },
  {
    path: "/signature",
    name: RouteNames.SIGNATURE,
    component: () => import("../views/SignatureView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Meine Unterschrift",
    },
  },
  {
    path: "/submit",
    redirect: "/submitRVC",
  },
  {
    path: "/submitRVC",
    name: RouteNames.SUBMIT_RVC,
    component: () => import("../views/SubmitRVC.vue"),
    meta: {
      requiresAuth: true,
      headline: "RVC-Bericht einreichen",
    },
  },
  {
    path: "/submitDbbg",
    name: RouteNames. SEARCH_AND_LIST_DBBG_GROUP,
    component: () => import("../views/DbbgOverview.vue"),
    meta: {
      requiresAuth: true,
      headline: "DBBG-Bericht einreichen",
    },
  },
  {
    path: "/submitDbbg/:id",
    name: RouteNames.SUBMIT_DBBG_REPORT,
    component: () => import("../components/submit/dbbg/GroupReport.vue"),
    meta: {
      requiresAuth: true,
      headline: "DBBG-Bericht einreichen",
    },
  },
  {
    path: "/submitDbbgRailiumTask/:id",
    name: RouteNames.SUBMIT_DBBG_RAILIUM_TASK_REPORT,
    component: () => import("../components/submit/dbbg/DbbgTaskReportSubmit.vue"),
    meta: {
      requiresAuth: true,
      headline: "DBBG Maschinentagesbericht einreichen",
    },
  },
  {
  path:"/submitDbbgFaplo/:id",
  name: RouteNames.SUBMIT_DBBG_FAPLO,
  component: () => import ("../components/submit/dbbg/DbbgReportFaploSubmit.vue"),
  meta:{
  requiresAuth: true,
  headline: "DBBG-Faplo hochladen",
  }, 
},
  {
    path: "/dbbgManagement",
    name: RouteNames.DBBG_MANAGEMENT,
    component: () => import("../components/management/dbbg/DbbgPositionManagement.vue"),
    meta: {
      requiresAuth: true,
      headline: "DBBG Verwalten",
    },
  },
  {
    path: "/reviewAndSettlement/",
    name: RouteNames.REVIEW_AND_SETTLEMENT,
    component: () => import("../views/ReviewAndSettlement.vue"),
    meta: {
      requiresAuth: true,
      headline: "Prüfen und Verrechnen",
    },
  },
  {
    path: "/reviewAndSettlement/review",
    name: RouteNames.REVIEW,
    component: () => import("../components/review/ReviewComponent.vue"),
    meta: {
      requiresAuth: true,
      headline: "Bericht prüfen",
    },
  },
  {
    path: "/reviewAndSettlement/settlement",
    name: RouteNames.SETTLEMENT,
    component: () => import("../components/settlement/SettlementComponent.vue"),
    meta: {
      requiresAuth: true,
      headline: "Bericht intern verrechnen",
    },
  },
  {
    path: "/monthlyOebbBackup",
    name: RouteNames.MONTHLY_OEBB_BACKUP,
    component: () => import("../components/monthlyBackup/MachineGroupOverview.vue"),
    meta: {
      requiresAuth: true,
      headline: "Monatssicherung an ÖBB senden",
    },
  },
  
  {
    path: "/externalSend",
    name: RouteNames.EXTRENAL_SEND,
    component: () => import("../components/external/send/ExternalSend.vue"),
    meta: {
      requiresAuth: true,
      headline: "Berichte für externe Freigabe bereitstellen",
    },
  },
  {
    path: "/externalReceive",
    name: RouteNames.EXTERNAL_RECEIVE,
    component: () =>
      import("../components/external/receive/ExternalReceive.vue"),
    meta: {
      requiresAuth: true,
      headline: "Extern freigegebene Berichte hochladen",
    },
  },
  {
    path: "/regionalManagement",
    name: RouteNames.REGIONAL_MANAGEMENT,
    component: () => import("../views/RegionalManagement.vue"),
    meta: {
      requiresAuth: true,
      headline: "Regionalmanagement",
    },
  },
  {
    path: "/job/:id",
    name: RouteNames.JOB,
    component: () => import("../components/job/JobComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/generator",
    name: RouteNames.GENERATOR,
    component: () => import("../views/GeneratorView.vue"),
    meta: {
      requiresAuth: true,
      headline: "AORVC Generator",
    },
  },
  {
    // Small workaround for MSAL: The redirect back looks like "#/code=0.AR8..."
    // VueRouter does not like that so to prevent useless console errors, we just make it a valid route to home.
    path: "/code=:afterCode(.*)",
    name: "OAuthCodeReturn",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

registerGuard(router);

export default router;
