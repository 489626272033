/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateSectionRequest,
  Section,
} from '../models/index';
import {
    CreateSectionRequestFromJSON,
    CreateSectionRequestToJSON,
    SectionFromJSON,
    SectionToJSON,
} from '../models/index';

export interface CreateLocationSectionAssignmentRequest {
    sectionId: number;
    locationId: number;
}

export interface CreateSectionOperationRequest {
    createSectionRequest: CreateSectionRequest;
}

export interface DeleteLocationSectionAssignmentRequest {
    sectionId: number;
    locationId: number;
}

export interface DeleteSectionRequest {
    sectionId: number;
}

export interface GetCompanySectionsRequest {
    companyId: number;
}

export interface GetSectionRequest {
    sectionId: number;
}

export interface GetSectionsRequest {
    filter?: string;
}

export interface PutSectionRequest {
    section: Section;
}

export interface PutSectionLocationRequest {
    sectionId: number;
    locationId: number;
}

/**
 * 
 */
export class SectionsApi extends runtime.BaseAPI {

    /**
     */
    async createLocationSectionAssignmentRaw(requestParameters: CreateLocationSectionAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling createLocationSectionAssignment.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling createLocationSectionAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}/locations/{locationId}/assignment`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createLocationSectionAssignment(requestParameters: CreateLocationSectionAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLocationSectionAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSectionRaw(requestParameters: CreateSectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Section>> {
        if (requestParameters.createSectionRequest === null || requestParameters.createSectionRequest === undefined) {
            throw new runtime.RequiredError('createSectionRequest','Required parameter requestParameters.createSectionRequest was null or undefined when calling createSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSectionRequestToJSON(requestParameters.createSectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionFromJSON(jsonValue));
    }

    /**
     */
    async createSection(requestParameters: CreateSectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Section> {
        const response = await this.createSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLocationSectionAssignmentRaw(requestParameters: DeleteLocationSectionAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling deleteLocationSectionAssignment.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling deleteLocationSectionAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}/locations/{locationId}/assignment`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLocationSectionAssignment(requestParameters: DeleteLocationSectionAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLocationSectionAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSectionRaw(requestParameters: DeleteSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling deleteSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSection(requestParameters: DeleteSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSectionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCompanySectionsRaw(requestParameters: GetCompanySectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Section>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanySections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/companies/{companyId}/sections`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionFromJSON));
    }

    /**
     */
    async getCompanySections(requestParameters: GetCompanySectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Section>> {
        const response = await this.getCompanySectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSectionRaw(requestParameters: GetSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Section>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling getSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionFromJSON(jsonValue));
    }

    /**
     */
    async getSection(requestParameters: GetSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Section> {
        const response = await this.getSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSectionsRaw(requestParameters: GetSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Section>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionFromJSON));
    }

    /**
     */
    async getSections(requestParameters: GetSectionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Section>> {
        const response = await this.getSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putSectionRaw(requestParameters: PutSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Section>> {
        if (requestParameters.section === null || requestParameters.section === undefined) {
            throw new runtime.RequiredError('section','Required parameter requestParameters.section was null or undefined when calling putSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionToJSON(requestParameters.section),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionFromJSON(jsonValue));
    }

    /**
     */
    async putSection(requestParameters: PutSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Section> {
        const response = await this.putSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putSectionLocationRaw(requestParameters: PutSectionLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling putSectionLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling putSectionLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/sections/{sectionId}/locations/{locationId}`.replace(`{${"sectionId"}}`, encodeURIComponent(String(requestParameters.sectionId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putSectionLocation(requestParameters: PutSectionLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSectionLocationRaw(requestParameters, initOverrides);
    }

}
