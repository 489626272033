/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    meterFrom: number;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    meterTo: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    validTo?: string;
}

/**
 * Check if a given object implements the Section interface.
 */
export function instanceOfSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "meterFrom" in value;
    isInstance = isInstance && "meterTo" in value;

    return isInstance;
}

export function SectionFromJSON(json: any): Section {
    return SectionFromJSONTyped(json, false);
}

export function SectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Section {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'meterFrom': json['meterFrom'],
        'meterTo': json['meterTo'],
        'validFrom': !exists(json, 'validFrom') ? undefined : json['validFrom'],
        'validTo': !exists(json, 'validTo') ? undefined : json['validTo'],
    };
}

export function SectionToJSON(value?: Section | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyId': value.companyId,
        'name': value.name,
        'meterFrom': value.meterFrom,
        'meterTo': value.meterTo,
        'validFrom': value.validFrom,
        'validTo': value.validTo,
    };
}

