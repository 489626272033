<style scoped lang="scss">
  .icon-user {
    margin-right: 0.5em;

    > img {
      border-radius: 50%;
      width: 2em;
      height: 2em;
    }

    span {
      line-height: 2em;
    }
  }

  .welcome {
    vertical-align: baseline;
    font-size: 17px;
  }
</style>

<template>
  <!--<i class="icon-user"><img src="https://upload.wikimedia.org/wikipedia/commons/7/70/Example.png" alt="icon"/></i>-->
  <span v-if="!!name" class="welcome">Hallo {{ name }}</span>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useMsal } from "@/composition-api/useMsal";

  const { accounts } = useMsal();

  const name = computed(() => {
    if (accounts.value.length > 0) {
      const name = accounts.value[0].name;
      if (name) {
        return name.split(" ")[0];
      }
    }
    return "";
  });
</script>