/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoolContractPosition
 */
export interface PoolContractPosition {
    /**
     * 
     * @type {number}
     * @memberof PoolContractPosition
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    shortDescription: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolContractPosition
     */
    validUntil?: string;
    /**
     * 
     * @type {number}
     * @memberof PoolContractPosition
     */
    activeAssignments?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolContractPosition
     */
    removedAssignments?: number;
}

/**
 * Check if a given object implements the PoolContractPosition interface.
 */
export function instanceOfPoolContractPosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "shortDescription" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "unit" in value;

    return isInstance;
}

export function PoolContractPositionFromJSON(json: any): PoolContractPosition {
    return PoolContractPositionFromJSONTyped(json, false);
}

export function PoolContractPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoolContractPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'shortDescription': json['shortDescription'],
        'description': json['description'],
        'unit': json['unit'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'validFrom': !exists(json, 'validFrom') ? undefined : json['validFrom'],
        'validUntil': !exists(json, 'validUntil') ? undefined : json['validUntil'],
        'activeAssignments': !exists(json, 'activeAssignments') ? undefined : json['activeAssignments'],
        'removedAssignments': !exists(json, 'removedAssignments') ? undefined : json['removedAssignments'],
    };
}

export function PoolContractPositionToJSON(value?: PoolContractPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'shortDescription': value.shortDescription,
        'description': value.description,
        'unit': value.unit,
        'info': value.info,
        'validFrom': value.validFrom,
        'validUntil': value.validUntil,
        'activeAssignments': value.activeAssignments,
        'removedAssignments': value.removedAssignments,
    };
}

