/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DbbgVerfuegbarePositionTyp = {
    ZeitTag: 'ZEIT_TAG',
    ZeitNacht: 'ZEIT_NACHT',
    ZeitSamstag: 'ZEIT_SAMSTAG',
    ZeitSonntag: 'ZEIT_SONNTAG',
    ZeitFeiertag: 'ZEIT_FEIERTAG',
    TransportSelbst: 'TRANSPORT_SELBST',
    TransportExtern: 'TRANSPORT_EXTERN',
    TransportPersonen: 'TRANSPORT_PERSONEN'
} as const;
export type DbbgVerfuegbarePositionTyp = typeof DbbgVerfuegbarePositionTyp[keyof typeof DbbgVerfuegbarePositionTyp];


export function DbbgVerfuegbarePositionTypFromJSON(json: any): DbbgVerfuegbarePositionTyp {
    return DbbgVerfuegbarePositionTypFromJSONTyped(json, false);
}

export function DbbgVerfuegbarePositionTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgVerfuegbarePositionTyp {
    return json as DbbgVerfuegbarePositionTyp;
}

export function DbbgVerfuegbarePositionTypToJSON(value?: DbbgVerfuegbarePositionTyp | null): any {
    return value as any;
}

