<template>
  <el-config-provider :locale="locale">
    <nav-bar />
    <div class="page-body">
      <router-view />
    </div>
    <el-affix position="bottom" :offset="15">
      <div
        class="page-footer"
        :class="{
          'warning-box': showWarning,
        }"
      >
        <span>Version {{ appVersion }} vom {{ appTimestamp }}</span>
        <span class="environment" v-if="showTestSystemWarning"
          >Du arbeitest am Testsystem!</span
        >
        <span class="environment" v-if="showProductionSystemWarning"
          >Du arbeitest am Produktivsystem!</span
        >
        <!-- Dummy for proper centering -->
        <span v-if="showWarning"
          >Version {{ appVersion }} vom {{ appTimestamp }}</span
        >
      </div>
    </el-affix>
  </el-config-provider>
  <debug-menu />
</template>

<script lang="ts" setup>
  import NavBar from "./components/NavBar.vue";
  import { dayjs, ElConfigProvider } from "element-plus";
  import de from "element-plus/es/locale/lang/de";
  import en from "element-plus/es/locale/lang/en";
  import {
    showProductionSystemWarning,
    showTestSystemWarning,
  } from "./utils/devutils";
  import DebugMenu from "./components/debug/DebugMenu.vue";
  import { formatIsoDateTime } from "./utils/dateFormats";
  import { computed, getCurrentInstance, ref, Ref, watch } from "vue";
  import { Language } from "element-plus/es/locale";

  const locale: Ref<Language> = ref(de);
  watch(
    getCurrentInstance()!.appContext.config.globalProperties.$language,
    (lng) => {
      if (lng === "de") {
        dayjs.locale("de", { weekStart: 1 });
        locale.value = de;
        console.log("changed locale to " + lng);
      } else {
        dayjs.locale("en");
        locale.value = en;
        console.log("changed locale to en");
      }
    },
    { immediate: true },
  );

  let hostnameIncludesPrd = window.location.hostname.includes(".prd.");
  console.log("running in production environment: " + hostnameIncludesPrd);

  const appVersion = __APP_VERSION__;
  const appTimestamp = formatIsoDateTime(__APP_TIMESTAMP__);

  const showWarning = computed(
    () => showProductionSystemWarning.value || showTestSystemWarning.value,
  );
</script>

<style lang="scss">
  @import "@vuepic/vue-datepicker/dist/main.css";

  #app {
    color: $app-color;
    height: 100%;
  }

  body,
  html {
    font-family: "Corporate S", Arial, sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: $app-bgcolor;
  }

  .page-body {
    margin: 0.5em;
  }

  .page-footer {
    color: #999999;
    font-size: 0.6em;
    margin: 1em 0.5em 0.5em 0.5em;

    display: flex;
    justify-content: space-between;

    &.warning-box span {
      &.environment {
        flex-grow: 1;
      }

      &:not(.environment) {
        flex-shrink: 0;
      }

      &:last-of-type:not(:only-of-type) {
        visibility: hidden;
      }
    }
  }

  .warning-box {
    border: solid 2px rgba($color: #ffa500, $alpha: 0.4);
    background-color: rgba($color: #f5deb3, $alpha: 0.9);
    padding: 0.3em;

    .environment {
      color: #222;
      font-size: 1.5em;
      text-align: center;
    }
  }

  .loading-app-bg {
    .el-loading-mask {
      background-color: rgba($app-bgcolor, 0.8);
    }
  }

  // Was not scoped in Review.vue (or Settlement.vue or ReviewAndSettlement.vue) and applied globally
  .el-loading-mask.is-fullscreen {
    background-color: rgba($app-bgcolor, 0.8);
  }

  :root {
    --dp-input-padding: 4px 30px 4px 12px;
    --dp-font-size: 14px;

    .el-alert {
      --el-alert-title-font-size: 1em;
      --el-alert-description-font-size: 1em;
    }
  }
</style>
