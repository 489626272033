/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement Generator
 * Generator für AORV-Angebote
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportSharepointRequest,
  GetZipRequest,
  MachineGroup,
} from '../models/index';
import {
    ExportSharepointRequestFromJSON,
    ExportSharepointRequestToJSON,
    GetZipRequestFromJSON,
    GetZipRequestToJSON,
    MachineGroupFromJSON,
    MachineGroupToJSON,
} from '../models/index';

export interface ExportSharepointOperationRequest {
    exportSharepointRequest: ExportSharepointRequest;
}

export interface GetZipOperationRequest {
    getZipRequest: GetZipRequest;
}

/**
 * 
 */
export class MachinegroupsApi extends runtime.BaseAPI {

    /**
     */
    async exportSharepointRaw(requestParameters: ExportSharepointOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportSharepointRequest === null || requestParameters.exportSharepointRequest === undefined) {
            throw new runtime.RequiredError('exportSharepointRequest','Required parameter requestParameters.exportSharepointRequest was null or undefined when calling exportSharepoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/export/sharepoint`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportSharepointRequestToJSON(requestParameters.exportSharepointRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportSharepoint(requestParameters: ExportSharepointOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportSharepointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMachineGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MachineGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/machinegroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineGroupFromJSON));
    }

    /**
     */
    async getMachineGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MachineGroup>> {
        const response = await this.getMachineGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getZipRaw(requestParameters: GetZipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.getZipRequest === null || requestParameters.getZipRequest === undefined) {
            throw new runtime.RequiredError('getZipRequest','Required parameter requestParameters.getZipRequest was null or undefined when calling getZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/export/zip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetZipRequestToJSON(requestParameters.getZipRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getZip(requestParameters: GetZipOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
