<style scoped lang="scss">
  .home {
    button {
      margin: 0.3em 0.5em;
    }
  }

  $grid-max-rows: 3;
  $grid-card-width: 15em;
  $grid-card-margin: 0.5em;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .card-container {
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($grid-card-width, 1fr));

      // Limit number of rows on larger displays
      $size: ($grid-card-width + $grid-card-margin * 2) * $grid-max-rows;
      @media (min-width: $size) {
        & {
          grid-template-columns: repeat($grid-max-rows, 1fr);
        }
      }

      .el-card {
        margin: $grid-card-margin;

        .card-header {
          font-weight: bold;
        }
      }
    }
  }
</style>

<template>
  <div class="home">
    <span v-if="!isAuthenticated"
      >Bitte anmelden, um die Applikation zu verwenden.</span
    >
    <template v-else>
      <div class="wrapper">
        <div class="card-container">
          <el-card>
            <template #header>
              <div class="card-header">Allgemein</div>
            </template>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.JOB_OVERVIEW })"
              :disabled="!roles.canUserReadReports.value"
            >
              Berichtsmanagement
            </el-button>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.SIGNATURE })"
            >
              Unterschrift
            </el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">Berichte Einreichen</div>
            </template>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.SUBMIT_RVC })"
              :disabled="!roles.canUserSubmitReport.value"
            >
              RVC
            </el-button>
            <el-button
              type="primary"
              @click="
                router.push({ name: RouteNames.SEARCH_AND_LIST_DBBG_GROUP })
              "
              :disabled="!roles.canUserSubmitReport.value"
            >
              DBBG
            </el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">Berichte prüfen &amp; verrechnen</div>
            </template>
            <el-button
              type="primary"
              @click="
                router.push({
                  name: RouteNames.REVIEW_AND_SETTLEMENT,
                  query: { t: 'review' },
                })
              "
              :disabled="
                !(
                  roles.canUserReviewReport.value ||
                  roles.isUserReportAdmin.value
                )
              "
            >
              Prüfen
            </el-button>
            <el-button
              type="primary"
              @click="
                router.push({
                  name: RouteNames.REVIEW_AND_SETTLEMENT,
                  query: { t: 'settlement' },
                })
              "
              :disabled="
                !(
                  roles.canUserBillReport.value || roles.isUserReportAdmin.value
                )
              "
            >
              Verrechnen
            </el-button>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.MONTHLY_OEBB_BACKUP })"
              :disabled="!roles.isUserReportAdmin"
            >
              Monatssicherungen
            </el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">Externe Berichtsfreigabe</div>
            </template>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.EXTRENAL_SEND })"
              :disabled="!roles.canUserCommunicateExternal.value"
            >
              Versenden
            </el-button>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.EXTERNAL_RECEIVE })"
              :disabled="!roles.canUserCommunicateExternal.value"
            >
              Empfangen
            </el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">Abnahme</div>
            </template>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.REGIONAL_MANAGEMENT })"
              :disabled="!roles.canUserApproveReport.value"
            >
              Regionalmanagement
            </el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">Stammdaten</div>
            </template>
            <el-button
              type="primary"
              @click="router.push({ name: RouteNames.GENERATOR })"
              :disabled="!roles.isUserGeneratorAdmin.value"
              title="Verträge für Arbeiten ohne Rahmenvertrag bearbeiten"
            >
              AORV-Verträge
            </el-button>
            <el-button
                type="primary"
                @click="router.push({ name: RouteNames.DBBG_MANAGEMENT })"
                :disabled="!roles.canUserSubmitReport.value"
            >
              DBBG-Leistungspositionen
            </el-button>
          </el-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from "vue-router";
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import { useOauthRoles } from "@/composition-api/useOauthRoles";
  import { RouteNames } from "@/router/RouteNames";
  import { ElButton, ElCard } from "element-plus";

  const isAuthenticated = useIsAuthenticated();

  const router = useRouter();
  const roles = useOauthRoles();
</script>
