<template>
  <el-button type="primary" v-on:click="loginRedirect">Anmelden</el-button>
</template>

<script setup lang="ts">
  import { useMsal } from "@/composition-api/useMsal";
  import { Page, scopesGraph } from "@/authConfig";
  import { RedirectRequest } from "@azure/msal-browser";

  const { instance } = useMsal();

  const loginRequest: RedirectRequest = {
    scopes: scopesGraph[Page.MAIN],
  };

  const loginPopup = () => {
    instance.loginPopup(loginRequest);
  };

  const loginRedirect = () => {
    instance.loginRedirect(loginRequest);
  };
</script>