<style scoped lang="scss">
  .logout {
    margin-left: 0.8em;
    vertical-align: baseline;
  }
</style>

<template>
  <WelcomeName />
  <el-link class="logout" v-on:click="logoutRedirect">Abmelden</el-link>
</template>

<script setup lang="ts">
  import { useMsal } from "@/composition-api/useMsal";
  import WelcomeName from "./WelcomeName.vue";

  const { instance } = useMsal();

  const logoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  const logoutRedirect = () => {
    instance.logoutRedirect();
  };
</script>