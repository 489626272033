/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DbbgVerfuegbarePositionTyp } from './DbbgVerfuegbarePositionTyp';
import {
    DbbgVerfuegbarePositionTypFromJSON,
    DbbgVerfuegbarePositionTypFromJSONTyped,
    DbbgVerfuegbarePositionTypToJSON,
} from './DbbgVerfuegbarePositionTyp';

/**
 * 
 * @export
 * @interface DbbgVerfuegbarePosition
 */
export interface DbbgVerfuegbarePosition {
    /**
     * 
     * @type {string}
     * @memberof DbbgVerfuegbarePosition
     */
    positionsNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgVerfuegbarePosition
     */
    name: string;
    /**
     * 
     * @type {DbbgVerfuegbarePositionTyp}
     * @memberof DbbgVerfuegbarePosition
     */
    typ: DbbgVerfuegbarePositionTyp;
    /**
     * 
     * @type {string}
     * @memberof DbbgVerfuegbarePosition
     */
    einheit?: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgVerfuegbarePosition
     */
    preis?: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgVerfuegbarePosition
     */
    gltigVon: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgVerfuegbarePosition
     */
    gltigBis: string;
}

/**
 * Check if a given object implements the DbbgVerfuegbarePosition interface.
 */
export function instanceOfDbbgVerfuegbarePosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "gltigVon" in value;
    isInstance = isInstance && "gltigBis" in value;

    return isInstance;
}

export function DbbgVerfuegbarePositionFromJSON(json: any): DbbgVerfuegbarePosition {
    return DbbgVerfuegbarePositionFromJSONTyped(json, false);
}

export function DbbgVerfuegbarePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgVerfuegbarePosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionsNummer': !exists(json, 'PositionsNummer') ? undefined : json['PositionsNummer'],
        'name': json['Name'],
        'typ': DbbgVerfuegbarePositionTypFromJSON(json['Typ']),
        'einheit': !exists(json, 'Einheit') ? undefined : json['Einheit'],
        'preis': !exists(json, 'Preis') ? undefined : json['Preis'],
        'gltigVon': json['GültigVon'],
        'gltigBis': json['GültigBis'],
    };
}

export function DbbgVerfuegbarePositionToJSON(value?: DbbgVerfuegbarePosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PositionsNummer': value.positionsNummer,
        'Name': value.name,
        'Typ': DbbgVerfuegbarePositionTypToJSON(value.typ),
        'Einheit': value.einheit,
        'Preis': value.preis,
        'GültigVon': value.gltigVon,
        'GültigBis': value.gltigBis,
    };
}

