/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JsonMachineGroupReportsCheck,
  JsonReportError,
  ReportPdfResponseBody,
  SignedMachineGroupsReportsCheckWithState,
} from '../models/index';
import {
    JsonMachineGroupReportsCheckFromJSON,
    JsonMachineGroupReportsCheckToJSON,
    JsonReportErrorFromJSON,
    JsonReportErrorToJSON,
    ReportPdfResponseBodyFromJSON,
    ReportPdfResponseBodyToJSON,
    SignedMachineGroupsReportsCheckWithStateFromJSON,
    SignedMachineGroupsReportsCheckWithStateToJSON,
} from '../models/index';

export interface BerichtPdfBearbeitenRequest {
    berichtPdf: string;
    vertragsnummer: number;
    maschinengruppe: string;
    berichtsnummer: number;
    signatur: string;
    sofortAbnahme?: boolean;
    zusatzPdf?: string;
    unterschreiben?: boolean;
}

export interface BerichtPruefenRequest {
    berichtslistenId: string;
}

export interface BerichtePruefenRequest {
    berichtslistenId: string;
}

export interface BerichtePruefenMitStatusRequest {
    sicherungZip?: string;
}

/**
 * 
 */
export class CheckApi extends runtime.BaseAPI {

    /**
     * Daten aus PDF-Bericht auslesen, unterschreiben und zurückgeben.
     */
    async berichtPdfBearbeitenRaw(requestParameters: BerichtPdfBearbeitenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportPdfResponseBody>> {
        if (requestParameters.berichtPdf === null || requestParameters.berichtPdf === undefined) {
            throw new runtime.RequiredError('berichtPdf','Required parameter requestParameters.berichtPdf was null or undefined when calling berichtPdfBearbeiten.');
        }

        if (requestParameters.vertragsnummer === null || requestParameters.vertragsnummer === undefined) {
            throw new runtime.RequiredError('vertragsnummer','Required parameter requestParameters.vertragsnummer was null or undefined when calling berichtPdfBearbeiten.');
        }

        if (requestParameters.maschinengruppe === null || requestParameters.maschinengruppe === undefined) {
            throw new runtime.RequiredError('maschinengruppe','Required parameter requestParameters.maschinengruppe was null or undefined when calling berichtPdfBearbeiten.');
        }

        if (requestParameters.berichtsnummer === null || requestParameters.berichtsnummer === undefined) {
            throw new runtime.RequiredError('berichtsnummer','Required parameter requestParameters.berichtsnummer was null or undefined when calling berichtPdfBearbeiten.');
        }

        if (requestParameters.signatur === null || requestParameters.signatur === undefined) {
            throw new runtime.RequiredError('signatur','Required parameter requestParameters.signatur was null or undefined when calling berichtPdfBearbeiten.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.berichtPdf !== undefined) {
            formParams.append('BerichtPdf', requestParameters.berichtPdf as any);
        }

        if (requestParameters.vertragsnummer !== undefined) {
            formParams.append('Vertragsnummer', requestParameters.vertragsnummer as any);
        }

        if (requestParameters.maschinengruppe !== undefined) {
            formParams.append('Maschinengruppe', requestParameters.maschinengruppe as any);
        }

        if (requestParameters.berichtsnummer !== undefined) {
            formParams.append('Berichtsnummer', requestParameters.berichtsnummer as any);
        }

        if (requestParameters.sofortAbnahme !== undefined) {
            formParams.append('SofortAbnahme', requestParameters.sofortAbnahme as any);
        }

        if (requestParameters.zusatzPdf !== undefined) {
            formParams.append('ZusatzPdf', requestParameters.zusatzPdf as any);
        }

        if (requestParameters.unterschreiben !== undefined) {
            formParams.append('Unterschreiben', requestParameters.unterschreiben as any);
        }

        if (requestParameters.signatur !== undefined) {
            formParams.append('Signatur', requestParameters.signatur as any);
        }

        const response = await this.request({
            path: `/api/v1/reports/processPdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportPdfResponseBodyFromJSON(jsonValue));
    }

    /**
     * Daten aus PDF-Bericht auslesen, unterschreiben und zurückgeben.
     */
    async berichtPdfBearbeiten(requestParameters: BerichtPdfBearbeitenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportPdfResponseBody> {
        const response = await this.berichtPdfBearbeitenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prüft Berichte einer bereits hochgeladenen RVC-Sicherung und gibt nur das Prüfungsergebnis des angegebenen Berichts zurück.
     */
    async berichtPruefenRaw(requestParameters: BerichtPruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JsonReportError>>> {
        if (requestParameters.berichtslistenId === null || requestParameters.berichtslistenId === undefined) {
            throw new runtime.RequiredError('berichtslistenId','Required parameter requestParameters.berichtslistenId was null or undefined when calling berichtPruefen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/check/{BerichtslistenId}/single`.replace(`{${"BerichtslistenId"}}`, encodeURIComponent(String(requestParameters.berichtslistenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JsonReportErrorFromJSON));
    }

    /**
     * Prüft Berichte einer bereits hochgeladenen RVC-Sicherung und gibt nur das Prüfungsergebnis des angegebenen Berichts zurück.
     */
    async berichtPruefen(requestParameters: BerichtPruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JsonReportError>> {
        const response = await this.berichtPruefenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prüft Berichte einer bereits hochgeladenen RVC-Sicherung und gibt das Prüfungsergebnis zurück.
     */
    async berichtePruefenRaw(requestParameters: BerichtePruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JsonMachineGroupReportsCheck>>> {
        if (requestParameters.berichtslistenId === null || requestParameters.berichtslistenId === undefined) {
            throw new runtime.RequiredError('berichtslistenId','Required parameter requestParameters.berichtslistenId was null or undefined when calling berichtePruefen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const response = await this.request({
            path: `/api/v1/reports/check/{BerichtslistenId}`.replace(`{${"BerichtslistenId"}}`, encodeURIComponent(String(requestParameters.berichtslistenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JsonMachineGroupReportsCheckFromJSON));
    }

    /**
     * Prüft Berichte einer bereits hochgeladenen RVC-Sicherung und gibt das Prüfungsergebnis zurück.
     */
    async berichtePruefen(requestParameters: BerichtePruefenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JsonMachineGroupReportsCheck>> {
        const response = await this.berichtePruefenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prüft Berichte der übergebenen RVC-Sicherung und gibt das Prüfungsergebnis zurück. Zusätzlich wird der Status der Berichte im Workflow angegeben.
     */
    async berichtePruefenMitStatusRaw(requestParameters: BerichtePruefenMitStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignedMachineGroupsReportsCheckWithState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", ["api://422c3330-34c8-4d12-89f8-045b08ece3bc/.default", "api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.sicherungZip !== undefined) {
            formParams.append('SicherungZip', requestParameters.sicherungZip as any);
        }

        const response = await this.request({
            path: `/api/v1/reports/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignedMachineGroupsReportsCheckWithStateFromJSON(jsonValue));
    }

    /**
     * Prüft Berichte der übergebenen RVC-Sicherung und gibt das Prüfungsergebnis zurück. Zusätzlich wird der Status der Berichte im Workflow angegeben.
     */
    async berichtePruefenMitStatus(requestParameters: BerichtePruefenMitStatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignedMachineGroupsReportsCheckWithState> {
        const response = await this.berichtePruefenMitStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
