/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgEinsatzSchicht
 */
export interface DbbgEinsatzSchicht {
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzSchicht
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgEinsatzSchicht
     */
    bis: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgEinsatzSchicht
     */
    wert: number;
}

/**
 * Check if a given object implements the DbbgEinsatzSchicht interface.
 */
export function instanceOfDbbgEinsatzSchicht(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "wert" in value;

    return isInstance;
}

export function DbbgEinsatzSchichtFromJSON(json: any): DbbgEinsatzSchicht {
    return DbbgEinsatzSchichtFromJSONTyped(json, false);
}

export function DbbgEinsatzSchichtFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgEinsatzSchicht {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'von': json['Von'],
        'bis': json['Bis'],
        'wert': json['Wert'],
    };
}

export function DbbgEinsatzSchichtToJSON(value?: DbbgEinsatzSchicht | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Von': value.von,
        'Bis': value.bis,
        'Wert': value.wert,
    };
}

