import {
  BrowserAuthOptions,
  Configuration as MsalConfig,
  LogLevel,
  ProtocolMode,
  PublicClientApplication,
  UrlString,
} from "@azure/msal-browser";
import { environment, Environment } from "./utils/environment";
import { parseClientIds } from "./utils/oauth/oauthHelper";

const clientIdsFrontend: { [key in Environment]: string } = parseClientIds(
  __CLIENT_IDS_FRONTEND__,
  {
    [Environment.DEVELOPMENT]: "f2eb222f-a04d-463e-87e2-3151ee1a7b06",
    [Environment.TEST]: "c16df78a-f1fc-4eb2-803a-93d618540e3f",
  },
);

const clientIdsBackend: { [key in Environment]: string } = parseClientIds(
  __CLIENT_IDS_BACKEND__,
  {
    [Environment.DEVELOPMENT]: "63e7db22-65f4-4fa6-bcd9-6a23983be170",
    [Environment.TEST]: "3b2a4a8a-d544-4703-9a44-30ea61a59b90"
  },
);

// Disables the security tests of MSAL. Handy for local, non-https OIDC server
//UrlString.prototype.validateAsUri = () => {};

//authority: "http://localhost:7071",

const browserAuthOptions: BrowserAuthOptions =
  environment == Environment.TEST
    ? {
        protocolMode: ProtocolMode.OIDC,
        clientId: clientIdsFrontend[environment],
        authority: "https://oidc.tst.apps.bbw.rsrg.com",
        knownAuthorities: ["oidc.tst.apps.bbw.rsrg.com"],
        redirectUri: `${window.location.origin}/`, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: `${window.location.origin}/logout`, // Must be registered as a SPA redirectURI on your app registration
      }
    : {
        protocolMode: ProtocolMode.AAD,
        clientId: clientIdsFrontend[environment],
        authority:
          "https://login.microsoftonline.com/ad96ab3f-3d64-4455-8acb-2a4f7badc27f",
        redirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: "/logout", // Must be registered as a SPA redirectURI on your app registration
      };

// Config object to be passed to Msal on creation
const msalConfig: MsalConfig = {
  auth: browserAuthOptions,
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export enum oauthRoles {
  REPORT_SUBMIT = "Report.Submit",
  REPORT_SUBMIT_DBBG = "Report.SubmitDbbg",

  REPORT_READ = "Report.Read",
  REPORT_REVIEW = "Report.Review",
  REPORT_APPROVAL = "Report.Approval",
  REPORT_ASSIGNMENTS = "Report.Assignments",
  REPORT_BILLING = "Report.Billing",
  REPORT_RESET = "Report.Reset",
  REPORT_EXTERNAL = "Report.External",

  REPORT_ADMIN = "Report.Admin",
  GENERATOR_ADMIN = "Generator.Admin",
}

export enum Page {
  MAIN,
  SIGNATURE,
  REPORT_MANAGEMENT,
  REPORT_SUBMIT,
  REPORT_REVIEW,
  REPORT_EXTERNAL,
  REPORT_APPROVAL,
  REPORT_BILLING,
  GENERATOR,
}

// api://63e7db22-65f4-4fa6-bcd9-6a23983be170/user_impersonation
const managementScopesBase = [
  `api://${clientIdsBackend[environment]}/.default`,
];
// We do it with .default because microsoft boys are fucktards and only make stuff that is broken.
// Alternative: tell downstream apis to always accept calls from backend. ...which is not possible for graph
export const scopesBackend: { [key in Page]: string[] } = {
  [Page.MAIN]: managementScopesBase,
  [Page.SIGNATURE]: [
    ...managementScopesBase,
    // 'api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read',
    // 'api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Write',
  ],
  [Page.REPORT_SUBMIT]: [
    ...managementScopesBase,
    //'api://6632314d-db49-4542-ae3f-a49b424092a0/Signature.Read',
  ],
  [Page.REPORT_MANAGEMENT]: managementScopesBase,
  [Page.REPORT_REVIEW]: managementScopesBase,
  [Page.REPORT_APPROVAL]: managementScopesBase,
  [Page.REPORT_BILLING]: managementScopesBase,
  [Page.REPORT_EXTERNAL]: managementScopesBase,
  [Page.GENERATOR]: managementScopesBase,
};

const graphScopesBase = ["User.Read", "User.ReadBasic.All"];
export const scopesGraph: { [key in Page]: string[] } = {
  [Page.MAIN]: graphScopesBase,
  [Page.SIGNATURE]: graphScopesBase,
  [Page.REPORT_SUBMIT]: graphScopesBase,
  [Page.REPORT_MANAGEMENT]: graphScopesBase,
  [Page.REPORT_REVIEW]: graphScopesBase,
  [Page.REPORT_APPROVAL]: graphScopesBase,
  [Page.REPORT_BILLING]: graphScopesBase,
  [Page.REPORT_EXTERNAL]: graphScopesBase,
  [Page.GENERATOR]: graphScopesBase,
};
