/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RechnungsdatenDbbg
 */
export interface RechnungsdatenDbbg {
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    rechnungsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    rechnungsdatum?: string;
    /**
     * Namenskürzel der RechnungsausstellerIn
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    zeichen?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    leistungsdatumStart?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    leistungsdatumEnde?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    bestellung?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    sortierfeldnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    bauvorhaben?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    maschineName?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    maschineKomponente?: string;
    /**
     * 
     * @type {string}
     * @memberof RechnungsdatenDbbg
     */
    kostenstelle?: string;
}

/**
 * Check if a given object implements the RechnungsdatenDbbg interface.
 */
export function instanceOfRechnungsdatenDbbg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechnungsdatenDbbgFromJSON(json: any): RechnungsdatenDbbg {
    return RechnungsdatenDbbgFromJSONTyped(json, false);
}

export function RechnungsdatenDbbgFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechnungsdatenDbbg {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rechnungsnummer': !exists(json, 'Rechnungsnummer') ? undefined : json['Rechnungsnummer'],
        'rechnungsdatum': !exists(json, 'Rechnungsdatum') ? undefined : json['Rechnungsdatum'],
        'zeichen': !exists(json, 'Zeichen') ? undefined : json['Zeichen'],
        'leistungsdatumStart': !exists(json, 'LeistungsdatumStart') ? undefined : json['LeistungsdatumStart'],
        'leistungsdatumEnde': !exists(json, 'LeistungsdatumEnde') ? undefined : json['LeistungsdatumEnde'],
        'bestellung': !exists(json, 'Bestellung') ? undefined : json['Bestellung'],
        'sortierfeldnummer': !exists(json, 'Sortierfeldnummer') ? undefined : json['Sortierfeldnummer'],
        'bauvorhaben': !exists(json, 'Bauvorhaben') ? undefined : json['Bauvorhaben'],
        'maschineName': !exists(json, 'MaschineName') ? undefined : json['MaschineName'],
        'maschineKomponente': !exists(json, 'MaschineKomponente') ? undefined : json['MaschineKomponente'],
        'kostenstelle': !exists(json, 'Kostenstelle') ? undefined : json['Kostenstelle'],
    };
}

export function RechnungsdatenDbbgToJSON(value?: RechnungsdatenDbbg | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Rechnungsnummer': value.rechnungsnummer,
        'Rechnungsdatum': value.rechnungsdatum,
        'Zeichen': value.zeichen,
        'LeistungsdatumStart': value.leistungsdatumStart,
        'LeistungsdatumEnde': value.leistungsdatumEnde,
        'Bestellung': value.bestellung,
        'Sortierfeldnummer': value.sortierfeldnummer,
        'Bauvorhaben': value.bauvorhaben,
        'MaschineName': value.maschineName,
        'MaschineKomponente': value.maschineKomponente,
        'Kostenstelle': value.kostenstelle,
    };
}

