/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BerichtAORV
 */
export interface BerichtAORV {
    /**
     * 
     * @type {string}
     * @memberof BerichtAORV
     */
    auftragsnummer: string;
    /**
     * 
     * @type {string}
     * @memberof BerichtAORV
     */
    angebotsnummer: string;
    /**
     * 
     * @type {number}
     * @memberof BerichtAORV
     */
    kundeId: number;
    /**
     * 
     * @type {string}
     * @memberof BerichtAORV
     */
    kundeName: string;
}

/**
 * Check if a given object implements the BerichtAORV interface.
 */
export function instanceOfBerichtAORV(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "auftragsnummer" in value;
    isInstance = isInstance && "angebotsnummer" in value;
    isInstance = isInstance && "kundeId" in value;
    isInstance = isInstance && "kundeName" in value;

    return isInstance;
}

export function BerichtAORVFromJSON(json: any): BerichtAORV {
    return BerichtAORVFromJSONTyped(json, false);
}

export function BerichtAORVFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerichtAORV {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auftragsnummer': json['Auftragsnummer'],
        'angebotsnummer': json['Angebotsnummer'],
        'kundeId': json['KundeId'],
        'kundeName': json['KundeName'],
    };
}

export function BerichtAORVToJSON(value?: BerichtAORV | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Auftragsnummer': value.auftragsnummer,
        'Angebotsnummer': value.angebotsnummer,
        'KundeId': value.kundeId,
        'KundeName': value.kundeName,
    };
}

