export function clone<T>(v: T): T {
  return JSON.parse(JSON.stringify(v));
}

/**
 * Gets all-string values of a string-enum. Type-safety is not possible so only pass a string-enum in!
 * <pre>
 * enum MyEnum {
 *   KEY = "VALUE",
 * }
 * </pre>
 */
export function getStringEnumValues(_enum: { [p: string]: string }): string[] {
  return Object.values(_enum).filter((item) => {
    return isNaN(Number(item)); // I don't remember, but I guess it filters the number-indexes out to retain only the string enum values
  });
}

export const toBase64DataUri = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const toBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    toBase64DataUri(file)
      .then((s) => {
        let idx = ("data:" + file.type + ";base64,").length;
        resolve(s.slice(idx));
      })
      .catch((e) => reject(e));
  });
