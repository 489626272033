/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonReportError } from './JsonReportError';
import {
    JsonReportErrorFromJSON,
    JsonReportErrorFromJSONTyped,
    JsonReportErrorToJSON,
} from './JsonReportError';

/**
 * 
 * @export
 * @interface JsonReportCheck
 */
export interface JsonReportCheck {
    /**
     * 
     * @type {number}
     * @memberof JsonReportCheck
     */
    berichtsNummer: number;
    /**
     * Gibt die maximale Einstufung der Prüfmeldungen zurück.
     * @type {number}
     * @memberof JsonReportCheck
     */
    maxEinstufung: number;
    /**
     * 
     * @type {Array<JsonReportError>}
     * @memberof JsonReportCheck
     */
    pruefMeldungen: Array<JsonReportError>;
}

/**
 * Check if a given object implements the JsonReportCheck interface.
 */
export function instanceOfJsonReportCheck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "berichtsNummer" in value;
    isInstance = isInstance && "maxEinstufung" in value;
    isInstance = isInstance && "pruefMeldungen" in value;

    return isInstance;
}

export function JsonReportCheckFromJSON(json: any): JsonReportCheck {
    return JsonReportCheckFromJSONTyped(json, false);
}

export function JsonReportCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReportCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berichtsNummer': json['BerichtsNummer'],
        'maxEinstufung': json['MaxEinstufung'],
        'pruefMeldungen': ((json['PruefMeldungen'] as Array<any>).map(JsonReportErrorFromJSON)),
    };
}

export function JsonReportCheckToJSON(value?: JsonReportCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BerichtsNummer': value.berichtsNummer,
        'MaxEinstufung': value.maxEinstufung,
        'PruefMeldungen': ((value.pruefMeldungen as Array<any>).map(JsonReportErrorToJSON)),
    };
}

