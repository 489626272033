/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgMaschinenTyp
 */
export interface DbbgMaschinenTyp {
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinenTyp
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinenTyp
     */
    weitereKomponente: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinenTyp
     */
    gltigVon: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgMaschinenTyp
     */
    gltigBis: string;
}

/**
 * Check if a given object implements the DbbgMaschinenTyp interface.
 */
export function instanceOfDbbgMaschinenTyp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "weitereKomponente" in value;
    isInstance = isInstance && "gltigVon" in value;
    isInstance = isInstance && "gltigBis" in value;

    return isInstance;
}

export function DbbgMaschinenTypFromJSON(json: any): DbbgMaschinenTyp {
    return DbbgMaschinenTypFromJSONTyped(json, false);
}

export function DbbgMaschinenTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgMaschinenTyp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'weitereKomponente': json['WeitereKomponente'],
        'gltigVon': json['GültigVon'],
        'gltigBis': json['GültigBis'],
    };
}

export function DbbgMaschinenTypToJSON(value?: DbbgMaschinenTyp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.name,
        'WeitereKomponente': value.weitereKomponente,
        'GültigVon': value.gltigVon,
        'GültigBis': value.gltigBis,
    };
}

