export enum RouteNames {
  JOB_OVERVIEW = "JobOverview",
  SIGNATURE ="Signature",
  REVIEW_AND_SETTLEMENT = "ReviewAndSettlement",
  SETTLEMENT ="Settlement",
  REVIEW = "Review",
  MONTHLY_OEBB_BACKUP = "MonthlyOebbBackup",
  EXTRENAL_SEND = "ExternalSend",
  EXTERNAL_RECEIVE ="ExternalReceive",
  REGIONAL_MANAGEMENT = "RegionalManagement",
  JOB = "Job",
  GENERATOR = "Generator",
  SUBMIT_RVC = "SubmitRvc",
  SEARCH_AND_LIST_DBBG_GROUP = "searchAndListDbbgGroup",
  SUBMIT_DBBG_REPORT = "SubmitDbbgReport",
  SUBMIT_DBBG_RAILIUM_TASK_REPORT = "submitDbbgRailiumTask",
  SUBMIT_DBBG_FAPLO = "submitDbbgFaplo",
  DBBG_MANAGEMENT = "dbbgManagement",
}
