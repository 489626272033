/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen
 * RVC und DBBG Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgRechnungsposition
 */
export interface DbbgRechnungsposition {
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsposition
     */
    positionsNummer: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsposition
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsposition
     */
    einheit: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsposition
     */
    menge: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsposition
     */
    einheitspreis: number;
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsposition
     */
    gesamtpreis: number;
}

/**
 * Check if a given object implements the DbbgRechnungsposition interface.
 */
export function instanceOfDbbgRechnungsposition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "positionsNummer" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "einheit" in value;
    isInstance = isInstance && "menge" in value;
    isInstance = isInstance && "einheitspreis" in value;
    isInstance = isInstance && "gesamtpreis" in value;

    return isInstance;
}

export function DbbgRechnungspositionFromJSON(json: any): DbbgRechnungsposition {
    return DbbgRechnungspositionFromJSONTyped(json, false);
}

export function DbbgRechnungspositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgRechnungsposition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionsNummer': json['PositionsNummer'],
        'text': json['Text'],
        'einheit': json['Einheit'],
        'menge': json['Menge'],
        'einheitspreis': json['Einheitspreis'],
        'gesamtpreis': json['Gesamtpreis'],
    };
}

export function DbbgRechnungspositionToJSON(value?: DbbgRechnungsposition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PositionsNummer': value.positionsNummer,
        'Text': value.text,
        'Einheit': value.einheit,
        'Menge': value.menge,
        'Einheitspreis': value.einheitspreis,
        'Gesamtpreis': value.gesamtpreis,
    };
}

