/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RailiumEinsatz
 */
export interface RailiumEinsatz {
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    einsatzart: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    angebotsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    auftragsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    kunde?: string;
}

/**
 * Check if a given object implements the RailiumEinsatz interface.
 */
export function instanceOfRailiumEinsatz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "einsatzart" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function RailiumEinsatzFromJSON(json: any): RailiumEinsatz {
    return RailiumEinsatzFromJSONTyped(json, false);
}

export function RailiumEinsatzFromJSONTyped(json: any, ignoreDiscriminator: boolean): RailiumEinsatz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'einsatzart': json['Einsatzart'],
        'region': json['Region'],
        'status': json['Status'],
        'angebotsnummer': !exists(json, 'Angebotsnummer') ? undefined : json['Angebotsnummer'],
        'auftragsnummer': !exists(json, 'Auftragsnummer') ? undefined : json['Auftragsnummer'],
        'kunde': !exists(json, 'Kunde') ? undefined : json['Kunde'],
    };
}

export function RailiumEinsatzToJSON(value?: RailiumEinsatz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.name,
        'Einsatzart': value.einsatzart,
        'Region': value.region,
        'Status': value.status,
        'Angebotsnummer': value.angebotsnummer,
        'Auftragsnummer': value.auftragsnummer,
        'Kunde': value.kunde,
    };
}

