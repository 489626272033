/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DbbgMaschinentyp,
  DbbgRechnungPositionenVorschau200Response,
  DbbgRechnungsPositionDefinition,
  DbbgRechnungsPositionDefinitionZuweisung,
  Rechnungsposition,
} from '../models/index';
import {
    DbbgMaschinentypFromJSON,
    DbbgMaschinentypToJSON,
    DbbgRechnungPositionenVorschau200ResponseFromJSON,
    DbbgRechnungPositionenVorschau200ResponseToJSON,
    DbbgRechnungsPositionDefinitionFromJSON,
    DbbgRechnungsPositionDefinitionToJSON,
    DbbgRechnungsPositionDefinitionZuweisungFromJSON,
    DbbgRechnungsPositionDefinitionZuweisungToJSON,
    RechnungspositionFromJSON,
    RechnungspositionToJSON,
} from '../models/index';

export interface AorvRechnungPositionenVorschauRequest {
    id: number;
}

export interface DbbgFaploUrlAbholenRequest {
    id: string;
}

export interface DbbgMaschineSpeichernRequest {
    dbbgMaschinentyp?: DbbgMaschinentyp;
}

export interface DbbgMaschinenLoeschenRequest {
    machineId: number;
}

export interface DbbgPositionsZuweisungAbfragenRequest {
    body: string;
}

export interface DbbgPositionsZuweisungLoeschenRequest {
    dbbgRechnungsPositionDefinitionZuweisung?: DbbgRechnungsPositionDefinitionZuweisung;
}

export interface DbbgPositionsZuweisungSpeichernRequest {
    dbbgRechnungsPositionDefinitionZuweisung?: DbbgRechnungsPositionDefinitionZuweisung;
}

export interface DbbgPositionsZuweisungSuchenRequest {
    requestBody: Array<number>;
}

export interface DbbgRechnungPositionenVorschauRequest {
    id: string;
}

export interface DbbgRechnungsPositionLoeschenRequest {
    positionId: number;
}

export interface DbbgRechnungsPositionSpeichernRequest {
    dbbgRechnungsPositionDefinition?: DbbgRechnungsPositionDefinition;
}

export interface DbbgRechnungsPositionenRequest {
    filter?: string;
}

/**
 * 
 */
export class DbbgApi extends runtime.BaseAPI {

    /**
     * Gibt eine Vorschau zurück, wie die Positionen der Rechnung aussehen würden.
     */
    async aorvRechnungPositionenVorschauRaw(requestParameters: AorvRechnungPositionenVorschauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Rechnungsposition>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aorvRechnungPositionenVorschau.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/aorv/report/{id}/invoicePositionsPreview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RechnungspositionFromJSON));
    }

    /**
     * Gibt eine Vorschau zurück, wie die Positionen der Rechnung aussehen würden.
     */
    async aorvRechnungPositionenVorschau(requestParameters: AorvRechnungPositionenVorschauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Rechnungsposition>> {
        const response = await this.aorvRechnungPositionenVorschauRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Holt die Download-URL für den Faplo ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt die Download-URL für den Faplo ab.
     */
    async dbbgFaploUrlAbholenRaw(requestParameters: DbbgFaploUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dbbgFaploUrlAbholen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/report/{id}/faplo/pdfUrl`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Holt die Download-URL für den Faplo ab. Diese ist nur für eine kurze Zeit gültig.
     * Holt die Download-URL für den Faplo ab.
     */
    async dbbgFaploUrlAbholen(requestParameters: DbbgFaploUrlAbholenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dbbgFaploUrlAbholenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene DBBG-Maschine
     */
    async dbbgMaschineSpeichernRaw(requestParameters: DbbgMaschineSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgMaschinentyp>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgMaschinentypToJSON(requestParameters.dbbgMaschinentyp),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgMaschinentypFromJSON(jsonValue));
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene DBBG-Maschine
     */
    async dbbgMaschineSpeichern(requestParameters: DbbgMaschineSpeichernRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgMaschinentyp> {
        const response = await this.dbbgMaschineSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt alle DBBG-Maschinen zurück.
     */
    async dbbgMaschineSuchenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgMaschinentyp>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgMaschinentypFromJSON));
    }

    /**
     * Gibt alle DBBG-Maschinen zurück.
     */
    async dbbgMaschineSuchen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgMaschinentyp>> {
        const response = await this.dbbgMaschineSuchenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Löscht eine DBBG-Maschine
     */
    async dbbgMaschinenLoeschenRaw(requestParameters: DbbgMaschinenLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling dbbgMaschinenLoeschen.');
        }

        const queryParameters: any = {};

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/machines`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht eine DBBG-Maschine
     */
    async dbbgMaschinenLoeschen(requestParameters: DbbgMaschinenLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgMaschinenLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt DBBG-Positions-Zuweisungen auf basis des RSQL-Filters zurück.
     */
    async dbbgPositionsZuweisungAbfragenRaw(requestParameters: DbbgPositionsZuweisungAbfragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgRechnungsPositionDefinitionZuweisung>>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling dbbgPositionsZuweisungAbfragen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positionAssignments/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgRechnungsPositionDefinitionZuweisungFromJSON));
    }

    /**
     * Gibt DBBG-Positions-Zuweisungen auf basis des RSQL-Filters zurück.
     */
    async dbbgPositionsZuweisungAbfragen(requestParameters: DbbgPositionsZuweisungAbfragenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgRechnungsPositionDefinitionZuweisung>> {
        const response = await this.dbbgPositionsZuweisungAbfragenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht eine vorhandene Positions-Zuweisungen
     */
    async dbbgPositionsZuweisungLoeschenRaw(requestParameters: DbbgPositionsZuweisungLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positionAssignments`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgRechnungsPositionDefinitionZuweisungToJSON(requestParameters.dbbgRechnungsPositionDefinitionZuweisung),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht eine vorhandene Positions-Zuweisungen
     */
    async dbbgPositionsZuweisungLoeschen(requestParameters: DbbgPositionsZuweisungLoeschenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgPositionsZuweisungLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene Positions-Zuweisungen
     */
    async dbbgPositionsZuweisungSpeichernRaw(requestParameters: DbbgPositionsZuweisungSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positionAssignments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgRechnungsPositionDefinitionZuweisungToJSON(requestParameters.dbbgRechnungsPositionDefinitionZuweisung),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene Positions-Zuweisungen
     */
    async dbbgPositionsZuweisungSpeichern(requestParameters: DbbgPositionsZuweisungSpeichernRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgPositionsZuweisungSpeichernRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt DBBG-Positions-Zuweisungen für PositionsIds zurück.
     * @deprecated
     */
    async dbbgPositionsZuweisungSuchenRaw(requestParameters: DbbgPositionsZuweisungSuchenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgRechnungsPositionDefinitionZuweisung>>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling dbbgPositionsZuweisungSuchen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positionAssignments/array`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgRechnungsPositionDefinitionZuweisungFromJSON));
    }

    /**
     * Gibt DBBG-Positions-Zuweisungen für PositionsIds zurück.
     * @deprecated
     */
    async dbbgPositionsZuweisungSuchen(requestParameters: DbbgPositionsZuweisungSuchenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgRechnungsPositionDefinitionZuweisung>> {
        const response = await this.dbbgPositionsZuweisungSuchenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Vorschau zurück, wie die Positionen der Rechnung aussehen würden.
     */
    async dbbgRechnungPositionenVorschauRaw(requestParameters: DbbgRechnungPositionenVorschauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgRechnungPositionenVorschau200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dbbgRechnungPositionenVorschau.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/report/{id}/invoicePositionsPreview`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgRechnungPositionenVorschau200ResponseFromJSON(jsonValue));
    }

    /**
     * Gibt eine Vorschau zurück, wie die Positionen der Rechnung aussehen würden.
     */
    async dbbgRechnungPositionenVorschau(requestParameters: DbbgRechnungPositionenVorschauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgRechnungPositionenVorschau200Response> {
        const response = await this.dbbgRechnungPositionenVorschauRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht eine vorhandene Rechnungsposition-Definition
     */
    async dbbgRechnungsPositionLoeschenRaw(requestParameters: DbbgRechnungsPositionLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling dbbgRechnungsPositionLoeschen.');
        }

        const queryParameters: any = {};

        if (requestParameters.positionId !== undefined) {
            queryParameters['positionId'] = requestParameters.positionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht eine vorhandene Rechnungsposition-Definition
     */
    async dbbgRechnungsPositionLoeschen(requestParameters: DbbgRechnungsPositionLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dbbgRechnungsPositionLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene Rechnungsposition-Definition
     */
    async dbbgRechnungsPositionSpeichernRaw(requestParameters: DbbgRechnungsPositionSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DbbgRechnungsPositionDefinition>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DbbgRechnungsPositionDefinitionToJSON(requestParameters.dbbgRechnungsPositionDefinition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DbbgRechnungsPositionDefinitionFromJSON(jsonValue));
    }

    /**
     * Erstellt eine neue oder speichert eine vorhandene Rechnungsposition-Definition
     */
    async dbbgRechnungsPositionSpeichern(requestParameters: DbbgRechnungsPositionSpeichernRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DbbgRechnungsPositionDefinition> {
        const response = await this.dbbgRechnungsPositionSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste an Rechnungsposition-Definitionen zurück.
     */
    async dbbgRechnungsPositionenRaw(requestParameters: DbbgRechnungsPositionenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DbbgRechnungsPositionDefinition>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/dbbg/positions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DbbgRechnungsPositionDefinitionFromJSON));
    }

    /**
     * Gibt eine Liste an Rechnungsposition-Definitionen zurück.
     */
    async dbbgRechnungsPositionen(requestParameters: DbbgRechnungsPositionenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DbbgRechnungsPositionDefinition>> {
        const response = await this.dbbgRechnungsPositionenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
