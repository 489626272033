<style scoped lang="scss">
  #authButton {
    margin-left: auto;
  }

  nav {
    width: 100%;
    box-sizing: border-box;
    background-color: #ffd100;
    margin-bottom: 0.5em;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    > ul {
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      > li {
        display: inline-flex;
        align-items: center;

        &.item-home {
          img {
            display: inline-block;

            height: min(5em, 7vh);
            max-width: 100%;
          }

          @keyframes ❤️ {
            0% {
              visibility: hidden;
            }
            99% {
              visibility: hidden;
            }
            100% {
              visibility: visible;
            }
          }

          &::after {
            display: none;
            visibility: hidden;
            position: absolute;
            top: 5em;
            content: "Made with ❤️ for you! And 😡 for Microsoft.";
            padding: 1em;
            background-color: #d1dbe5;
            border: solid 1px #000;
            border-radius: 1em;
          }

          &:hover::after {
            display: block;
            animation: ❤️ 20s;
            visibility: visible;
          }
        }

        &.item {
          margin-left: auto;
        }

        &.item-login {
          margin-left: auto;
        }

        > * {
          display: inline-block;
          margin: 0.5em;
        }
      }
    }
  }
</style>

<template>
  <nav ref="navbar">
    <ul>
      <li class="item-home">
        <router-link to="/">
          <img src="/logo-bbw-rsrg.svg" alt="BBW-RSRG-Logo" />
        </router-link>
      </li>
      <li v-if="route.meta.headline" class="item">
        <h1>{{ route.meta.headline }}</h1>
      </li>
      <li class="item-login">
        <div>
          <SignOutButton v-if="isAuthenticated" />
          <SignInButton v-else />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import SignInButton from "./SignInButton.vue";
  import SignOutButton from "./SignOutButton.vue";
  import { ref, watch } from "vue";
  import { showProductionSystemWarning } from "@/utils/devutils";
  import { useRoute } from "vue-router";

  const isAuthenticated = useIsAuthenticated();

  const navbar = ref<HTMLElement>();
  const route = useRoute();

  watch(
    [showProductionSystemWarning, navbar],
    (v) => {
      if (showProductionSystemWarning.value && navbar.value) {
        navbar.value.style.backgroundColor = "rgb(255 105 72)";
      }
    },
    { immediate: true },
  );
</script>